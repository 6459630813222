@import "../../../styles/var";
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 30px);
    }
}
@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -30px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
.chooseAccount {
    position: relative;

    .title {
        text-align: center;
        margin-bottom: 40px;
        @media (max-width: $md6+px) {
            margin-bottom: 20px;
        }
    }
    .swiperWr {
        position: relative;
        :global(.chooseAccountTypeSwiper) {
            max-width: 1920px;
            min-height: 100%;
            @media (min-width: 1920px) {
            }
            @media (max-width: $md1+px) {
            }
            @media (max-width: $md2+px) {
            }
        }
        :global(.swiper) {
            overflow: visible;
        }
        :global(.chooseAccountTypeSwiperSlide) {
            width: 526px;
            height: auto !important;
            padding: 20px 0;
            @media (max-width: $md1+px) {
                width: 460px;
            }
            @media (max-width: $md2+px) {
                width: 413px;
            }
            @media (max-width: $md4+px) {
                width: 370px;
            }
            @media (max-width: $md5+px) {
                width: 340px;
            }
            @media (max-width: 680px) {
                width: 100%;
                padding-bottom: 30px;
            }
        }
        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 75px;

            height: 100%;
            border-radius: 10px;
            padding: 30px 40px 20px 40px;
            // transition: all 0.3s;
            border: 1px solid transparent;
            background: #363636;
            border-radius: 10px;
            transform: translate(0, 200px);
            @media (max-width: $md3+px) {
                transform: translate(0, 0px);
            }
            @media (max-width: $md4+px) {
                padding: 30px 30px;
            }
            @media (max-width: 680px) {
                padding: 20px 20px 30px 20px;
            }
            &:hover {
                .cardImg {
                    width: 50%;
                }
            }
            @media (max-width: $md1+px) {
                gap: 60px;
            }
            @media (max-width: $md2+px) {
                gap: 50px;
            }
            @media (max-width: $md4+px) {
                gap: 40px;
            }
            &Content {
                position: relative;
                z-index: 5;
            }
            &Desc {
                display: flex;
                justify-content: flex-end;
                span {
                    transition: all 0.3s;
                    border-radius: 30px;
                    display: inline-flex;
                    padding: 8px 16px;
                    font-family: $inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    @media (max-width: $md1+px) {
                        font-size: 12px;
                    }
                    @media (max-width: $md4+px) {
                        font-size: 10px;
                    }
                }
            }
            &Title {
                margin-top: 64px;
                @media (max-width: $md1+px) {
                    margin-top: 30px;
                }
                @media (max-width: 680px) {
                    margin-top: 20px;
                }
            }
            ul {
                font-weight: 400 !important;
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin: 20px 0 40px 30px;
                @media (max-width: $md1+px) {
                    margin: 20px 0 26px 26px;
                }
                @media (max-width: $md4+px) {
                    gap: 12px;
                }
                @media (max-width: 680px) {
                    gap: 8px;
                    margin: 20px 0 20px 26px;
                }
                li {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: calc(-15px - 18px);
                        width: 18px;
                        height: 18px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        @media (max-width: $md1+px) {
                            width: 14px;
                            height: 14px;
                            left: calc(-15px - 14px);
                            top: 4px;
                        }
                    }
                }
            }
            &Text {
            }
            &Btn {
                position: relative;
                z-index: 5;
                display: flex;
                justify-content: center;
                .btn {
                    display: inline-flex;
                    cursor: pointer;
                    font-family: $inter;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 115%;
                    padding: 13px 30px;
                    display: inline-flex;
                    border-radius: 30px;
                    transition: all 0.3s;
                    @media (max-width: $md2+px) {
                        font-size: 18px;
                    }
                    @media (max-width: $md4+px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                    &:hover {
                        background: transparent;
                    }
                }
            }
            &Img {
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: 0;
                height: auto;
                width: 100%;
                transition: all 0.3s;
            }
        }
    }
    .pagination {
        position: relative;
        z-index: 10;
        display: flex;
        gap: 10px;
        @media (max-width: 680px) {
            display: none;
        }
        img {
            opacity: 0.5;
            transition: all 0.3s;
            cursor: pointer;
            height: auto;
            width: 40px;
            &:hover {
                opacity: 1;
            }
        }
        .pagPrev {
            transform: rotate(180deg);
        }
    }
    :global(.swiper-pagination) {
        @media (min-width: 681px) {
            display: none;
        }
        bottom: 0px !important;
    }
    :global(.swiper-pagination-bullet) {
        background: #fff !important;
        opacity: 1 !important;
    }
    :global(.swiper-pagination-bullet-active) {
        background: #ed3432 !important;
        opacity: 1 !important;
    }
}

.card {
}
.cardItem {
}
.cardItemImg {
    img {
        height: 60px;
        object-fit: contain;
    }
    margin-bottom: 40px;
}

.cardItemTitle {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;

    font-family: $inter;
}
.cardItemDesc {
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.7) !important;
}
.chooseAccount {
}
.chooseAccountTop {
    position: relative;
}
.chooseAccountTopTitle {
    margin-bottom: 240px;
    max-width: 730px;
    @media (max-width: $md3+px) {
        margin-bottom: 688px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 550px;
    }
    @media (max-width: 500px) {
        margin-bottom: 410px;
    }
}
.chooseAccountTopInfo {
    max-width: 550px;
    margin-bottom: 60px;
    position: relative;
    z-index: 3;
}
.chooseAccountTopInfoTitle {
    margin-bottom: 30px;
    font-weight: 500 !important;
}
.chooseAccountTopPeople {
    position: absolute;
    right: -150px;
    top: 75px;
    width: 916px;
    height: 1459px;
    transform: translate(100px, 100px) scale(0.7);
    @media (max-width: $md3+px) {
        transform: translate(0px, 0px) scale(1);
    }
    @media (max-width: $md1+px) {
        right: -200px;
        width: 816px;
        height: 1359px;
        top: 0;
    }
    @media (max-width: $md3+px) {
        top: 140px;
    }
    @media (max-width: $md5+px) {
        width: 616px;
        right: -150px;
        height: 1159px;
        top: 90px;
    }
    @media (max-width: 500px) {
        width: 516px;
        right: -150px;
        height: 1259px;
        top: -75px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.chooseAccountTopMonet1 {
    position: absolute;
    right: 600px;
    top: 350px;

    width: 111px;
    height: 116px;
    transform: translate(-150px, -150px);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
        opacity: 1;
    }
    @media (max-width: $md1+px) {
        right: 550px;
        width: 100px;
        height: 100px;
        right: 480px;
        top: 300px;
    }
    @media (max-width: $md3+px) {
        top: 440px;
    }
    @media (max-width: $md5+px) {
        width: 70px;
        height: 70px;
        right: 400px;
        top: 390px;
    }
    @media (max-width: 500px) {
        width: 50px;
        height: 50px;
        right: 270px;
        top: 330px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim linear 1.5s alternate infinite");
    }
}
.chooseAccountTopMonet2 {
    position: absolute;
    right: 470px;
    top: 140px;
    transform: translate(-120px, -150px);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
        opacity: 1;
    }

    width: 104px;
    height: 114px;
    @media (max-width: $md1+px) {
        right: 350px;
        top: 140px;
        width: 100px;
        height: 100px;
    }
    @media (max-width: $md3+px) {
        top: 280px;
    }
    @media (max-width: $md5+px) {
        width: 70px;
        height: 70px;
        right: 300px;
        top: 270px;
    }
    @media (max-width: 500px) {
        width: 50px;
        height: 50px;
        right: 200px;
        top: 240px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim1 linear 1.5s alternate infinite");
    }
}

.chooseAccountTopMonet3 {
    position: absolute;
    right: 280px;
    top: 0px;
    width: 85px;
    height: 93px;
    transform: translate(0px, -150px);
    opacity: 0;
    @media (max-width: $md1+px) {
        right: 210px;
        top: -50px;
    }
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
        opacity: 1;
    }
    @media (max-width: $md3+px) {
        top: 90px;
    }
    @media (max-width: $md5+px) {
        width: 70px;
        height: 70px;
        top: 100px;
        right: 170px;
    }
    @media (max-width: 500px) {
        top: 110px;
        right: 120px;
        width: 50px;
        height: 50px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim2 linear 1.5s alternate infinite");
    }
}

.chooseAccountTopMonet4 {
    position: absolute;
    right: 50px;
    top: 93px;

    width: 137px;
    height: 138px;
    transform: translate(130px, -150px);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
        opacity: 1;
    }
    @media (max-width: $md1+px) {
        right: 0px;
        width: 110px;
        height: 110px;
        top: 63px;
    }
    @media (max-width: $md3+px) {
        top: 203px;
    }
    @media (max-width: $md5+px) {
        width: 70px;
        height: 70px;
        top: 190px;
        right: 50px;
    }
    @media (max-width: 500px) {
        width: 50px;
        height: 50px;
        top: 190px;
        right: 30px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim1 linear 1.5s alternate infinite");
    }
}

.chooseAccountTopMonet5 {
    position: absolute;
    right: 0;

    top: 300px;
    width: 108px;
    height: 104px;
    @media (max-width: $md1+px) {
        right: 0px;
        top: 200px;
        width: 90px;
        height: 90px;
    }
    @media (max-width: $md3+px) {
        top: 340px;
    }
    transform: translate(150px, -150px);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
        opacity: 1;
    }
    @media (max-width: $md5+px) {
        width: 70px;
        height: 70px;
        top: 290px;
    }
    @media (max-width: 500px) {
        width: 50px;
        height: 50px;
        right: -10px;
        top: 260px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim linear 1.5s alternate infinite");
    }
}
:global(.pageBg) {
    // display: none;
}
.chooseAccount {
}

.chooseAccountRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
}
.chooseAccountRowLeft {
}
.chooseAccountRowLeft {
}
.chooseAccountRowLeftTitle {
    color: #fff !important;
}
