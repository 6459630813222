.card .testimonial-left-button {
    position: absolute;
    /* margin-left: 435px; */
    top: 50%;
    left: 32%;
    /* width: 24px;
  height: 8px; */
    background-color: #3cbeec;
    z-index: 4;
}
.card-card {
    // @media (max-width: 540px) {
    //     width: 95% !important;
    //     margin: 0 auto;
    // }
}
.card .testimonial-right-button {
    position: absolute;
    /* margin-left: 838px; */
    top: 50%;
    right: 32%;
    box-shadow: none;
    /* width: 24px;
  height: 8px; */
    background-color: #3cbeec;
    z-index: 4;
}
.card .card-button.right {
    right: 0%;
}

.react-stacked-center-carousel {
    overflow: unset !important;
    align-items: center;
    display: flex;
    .card-card {
        opacity: 0.4;
    }
    & > div {
        // z-index: 11 !important;
    }
    @media (max-width: 540px) {
        max-width: 100% !important;
        width: 100%;
        & > div {
            max-width: 100% !important;
            width: 100%;
            left: unset !important;
        }
    }
}

.card-card {
    transition: all 300ms ease;
    cursor: pointer;
    width: 100%;
    border-radius: 3px;

    /* height:300px; */
    position: relative;
    background: #363636;

    border-radius: 20px;

    overflow: hidden;
}

.card-card:hover {
    transform: scale(1.05);
}

.react-stacked-center-carousel-slide-0 .card-card {
    cursor: default;
    opacity: 1;
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
    transform: none;
}

.fill {
    width: 100%;
    height: 100%;
}
.card-carrier {
    background: transparent !important;
    padding-top: 3%;
    border: none;
}

.card-card .card-overlay {
    user-select: none;
    position: absolute;

    transition: all 300ms ease;
    border-radius: 40px;
}

.card-card:hover .card-overlay {
    background-color: #0000002d;
}

.react-stacked-center-carousel-slide-0 .card-overlay {
    background-color: #00000099 !important;
}

/* .card-card .cover-image {
  /* object-fit: cover; */

.card-card .cover {
    position: absolute;
    transition: opacity 300ms ease;
}
.react-stacked-center-carousel-slide-0 .card-card .cover {
    transition: opacity 300ms ease, z-index 0ms 300ms;
}

.card-card .cover.on {
    opacity: 1;
    z-index: 1;
}

.card-card .cover.off {
    opacity: 0;
    z-index: -1;
}

.card-card .detail {
    display: flex;
}
.card-card .video {
    width: 40%;
}
.card-card .discription {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.card-card .discription > p {
    text-align: center;
    padding-top: 40px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: justified;
}
.card-card > p {
    text-align: center;
    padding-top: 20px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: justified;
}

@media screen and (max-width: 540px) {
    body {
        overflow-x: hidden;
    }

    .card-card {
        transition: all 300ms ease;
        cursor: pointer;

        width: 100%;

        /* height:300px; */
        position: relative;
    }

    .card .testimonial-left-button {
        position: absolute;
        /* margin-left: 435px; */
        top: 45%;
        left: 0%;
        /* width: 24px;
    height: 8px; */
        background-color: #3cbeec;
        z-index: 4;
    }
    .card .testimonial-right-button {
        position: absolute;
        /* margin-left: 838px; */
        top: 45%;
        left: 90%;
        box-shadow: none;
        /* width: 24px;
    height: 8px; */
        background-color: #3cbeec;
        z-index: 4;
    }
}

@media screen and (min-width: 765px) and (max-width: 992px) {
    .card .testimonial-left-button {
        position: fixed;
        /* margin-left: 435px; */
        top: 25vh;
        left: 17%;
        /* width: 24px;
      height: 8px; */
        background-color: red;
        z-index: 4;
    }
    .card .testimonial-right-button {
        position: fixed;
        /* margin-left: 838px; */
        top: 25%;
        left: 77%;
        box-shadow: none;
        /* width: 24px;
      height: 8px; */
        background-color: #3cbeec;
        z-index: 4;
    }
}
