@import "../../../styles/var";

.five {
    background-color: #363636;
    overflow: hidden;
    width: 100%;
    position: relative;
}
.fiveImg {
    position: absolute;
    width: 100%;
    @media (max-width: 1512px) {
        height: 272px;
        width: 1512px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.fiveBody {
}
.fiveRow {
    padding: 80px 0 138px 0;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        padding: 40px 0 0px 0;
    }
}
.fiveRowLeft {
    flex: 0 1 460px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 60px;
    }
}
.fiveRowLeftImg {
    position: absolute;

    display: block;
    bottom: 0;
    left: -100px;
    width: 661px;
    height: 566px;

    @media (max-width: $md2+px) {
        width: 561px;
        height: 466px;
        left: -50px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translate(-200px, 0);
        @media (max-width: $md3+px) {
            transform: translate(0px, 0);
        }
    }
}
.fiveRowLeftTitle {
    margin-bottom: 40px;
    @media (max-width: $md3+px) {
        max-width: 460px;
        margin-bottom: 20px;
    }
}
.fiveRowLeftText {
    @media (max-width: $md3+px) {
        max-width: 460px;
    }
}
.fiveRowRight {
    flex: 0 1 663px;
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
}
.fiveRowRightItem {
    margin-bottom: 60px;
    &:last-child {
        margin-bottom: 0;
    }
}
.fiveRowRightItemImg {
    margin-bottom: 20px;
}
.fiveRowRightItemTitle {
    margin-bottom: 20px;
}
.fiveRowRightItemSubTitle {
}
.fiveMobImg {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        img {
            max-width: 100%;
        }
    }
}
