@import "../../../styles/var";

.threeBody {
    padding: 100px 0 162px 0;
    position: relative;
    @media (max-width: $md2+px) {
        padding: 100px 0 102px 0;
    }
    @media (max-width: $md3+px) {
        padding: 70px 0 70px 0;
    }
    @media (max-width: $md4+px) {
        padding: 40px 0 40px 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../assets/img/Candidates/4/1.png") 0 0 / cover no-repeat;
        @media (max-width: $md6+px) {
            background: #242424;
        }
    }
}
.threeTitle {
    max-width: 800px;
    text-align: center;
    margin: 0 auto 60px auto;
    position: relative;
    color: #fff !important;
    z-index: 2;
    @media (max-width: $md2+px) {
        max-width: 700px;
    }
    @media (max-width: $md4+px) {
        max-width: 600px;
    }
    @media (max-width: $md6+px) {
        margin: 0 auto 30px auto;
    }
}
.threeForm {
    position: relative;
    z-index: 2;
    max-width: 463px;
    margin: 0 auto;
    text-align: center;
}
.threeFormInput {
    margin-bottom: 12px;
    width: 100%;
    height: 45px;
    background: #242424;
    @media (max-width: $md6+px) {
        background: #363636;
    }
    border-radius: 8px;
    input {
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0 20px;
        background: transparent;
    }
}
.threeFormTextarea {
    width: 100%;

    background: #242424;
    border-radius: 8px;
    padding-right: 10px;
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
    @media (max-width: $md6+px) {
        background: #363636;
    }
    input,
    textarea {
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 20px;
        background: transparent;
    }
    textarea {
        min-height: 90px;
        resize: none;

        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #ed3432;
        }
        &::-webkit-scrollbar {
            width: 8px;

            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 25px;
            background-color: #ed3432;
        }
    }
}
.threeFormBtn {
    margin-top: 40px;
    button {
        padding: 14px 30px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        background: #ed3432;
        border-radius: 30px;
        font-family: $inter;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 16px;

        color: #ffffff;
        transition: all 0.3s;
        border: 1px solid #ed3432;
        background: #ed3432;
        box-shadow: 0px 4px 14px rgba(237, 52, 50, 0.45);
        border-radius: 40px;
        @media (max-width: $md2+px) {
            font-size: 16px;
            line-height: 19px;
        }

        &:hover {
            color: #ed3432 !important;
            background: transparent;
            box-shadow: 0px 0px 0px rgba(237, 52, 50, 0.45);
        }
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
