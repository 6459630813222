@import "../../../styles/var";

.first {
    position: relative;
    padding: 60px 0 0 0;
    @media (max-width: $md3+px) {
        padding: 90px 0 0 0;
    }
}
.firstBg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @media (max-width: $md2+px) {
            width: 1418px;
            height: 258px;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
.firstBody {
}
.firstRow {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.firstRowLeft {
    flex: 0 1 600px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.firstTitle {
}
.firstSubtitle {
    margin-bottom: 40px;
    @media (max-width: $md3+px) {
        max-width: 600px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.firstText {
    margin-bottom: 60px;
    @media (max-width: $md3+px) {
        max-width: 600px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 40px;
    }
}
.firstBtn {
}
.firstRowRight {
    transform: translate(400px, 0);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0px, 0);
        opacity: 1;
    }
    flex: 0 1 618px;
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    img {
        width: 100%;
    }
}
