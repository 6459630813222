@import "../../../styles/var";
.slider {
    margin-bottom: 100px;
    @media (max-width: 540px) {
        padding: 0 18px;
    }
}

.tradersSliderSlideItemTopStars {
}

.tradersSliderSlideItemTopStars {
    min-width: 100px;
    width: 100px;
    background-color: #d0d0d0;
    height: 18px;
    display: flex;
    position: relative;
}
.tradersSliderSlideItemTopStarsProgress {
    left: 0;
    top: 0;
    position: absolute;

    height: 100%;

    &1 {
        background-color: #ff3622;
    }
    &2 {
        background-color: #ff8622;
    }
    &3 {
        background-color: #fece00;
    }
    &4 {
        background-color: #72cf12;
    }
    &5 {
        background-color: #00b67b;
    }
}
.tradersSliderSlideItemTopStarsStar {
    width: 20%;
    height: 100%;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0px;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 14px;
        height: 12px;
        background: url("../../../assets/img/MainPage/OurTraders/star.png") center / contain no-repeat;
    }
    position: relative;
    &:before {
        content: "";
        display: inline-block;
        right: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: 1px solid #fff;
    }
}

.slide {
    padding: 50px 100px 50px 100px;
    height: 100%;
    @media (max-width: $md3+px) {
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: $md6+px) {
        padding: 20px 20px 20px 20px;
    }
}
.slideTop {
    display: flex;
    max-width: 370px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
    @media (max-width: $md6+px) {
        justify-content: flex-start;
    }
}
.slideTopLeft {
    margin-right: 20px;
    img {
        object-fit: contain;
        height: 60px;
    }
}
.slideTopRight {
    display: flex;
}
.slideTopRightStars {
}
.slideTopRightStarsName {
    margin-right: 20px;
    margin-bottom: 15px;
    color: #fff !important;
}
.slideTopRightStarsWrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.tradersSliderSlideItemTopStars {
}
.tradersSliderSlideItemTopStarsProgress {
}
.tradersSliderSlideItemTopStarsStar {
}
.slideTopRightStarsSubname {
    flex: 0 1 100%;
    color: #fff !important;
}
.slideText {
    color: #fff !important;
}

.arrows {
    display: flex;
    justify-content: space-between;
    max-width: 100px;
    cursor: pointer;
    margin: 50px auto 0 auto;
}

.title {
    margin-bottom: 0px;
    text-align: center;
    color: #fff !important;
    @media (max-width: $md3+px) {
        margin-bottom: 40px;
    }
}
