@import "../../../styles/var";

.second {
}
.secondBody {
}
.secondRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.secondRowLeft {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.secondRowLeftTitle {
    color: #fff !important;
    margin-bottom: 36px;
}
.secondRowLeftList {
}
.secondRowLeftListItem {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    img {
        margin-right: 12px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.secondRowRight {
    flex: 0 1 604px;
    transform: translate(200px, 0);
    @media (max-width: $md3+px) {
        transform: translate(0px, 0);
    }
    @media (max-width: $md2+px) {
        flex: 0 1 554px;
    }
    img {
        max-width: 100%;
    }
}
