@import "../../../styles/var";

.button {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    background: #ed3432;
    border-radius: 30px;
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #ffffff;
    transition: all 0.3s;
    border: 1px solid #ed3432;
    background: #ed3432;
    box-shadow: 0px 4px 14px rgba(237, 52, 50, 0.45);
    border-radius: 40px;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        color: #ed3432;
        background: transparent;
        box-shadow: 0px 0px 0px rgba(237, 52, 50, 0.45);
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
