@import "../../../styles/var";

.footer {
    margin-bottom: 40px;
}
.footerBody {
}
.footerRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md5+px) {
        flex-wrap: wrap;
    }
}
.footerRowLeft {
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.footerRowRight {
    display: flex;
    @media (max-width: $md6+px) {
        flex-wrap: wrap;
    }
}
.footerRowRightContacts {
    @media (max-width: $md6+px) {
        flex: 0 1 100%;
        margin-bottom: 20px;
    }
}
.footerRowRightContactsTitle {
    margin-bottom: 10px;
    font-weight: 500 !important;
}
.footerRowRightContactsText {
    margin-bottom: 5px;
    font-weight: 400 !important;
    &:nth-child(2) {
        font-weight: 300 !important;
    }
}
.footerRowRightShedule {
    margin-left: 125px;
    @media (max-width: $md4+px) {
        margin-left: 65px;
    }
    @media (max-width: $md6+px) {
        margin-left: 0;
    }
}
.footerRowRightSheduleTitle {
    margin-bottom: 10px;
    font-weight: 500 !important;
}
.footerRowRightSheduleText {
    margin-bottom: 5px;
}
