@import "../../../styles/var";

.first {
}
.firstBody {
}
.firstRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.firstRowLeft {
    flex: 0 1 554px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.firstRowLeftTitle {
    @media (max-width: $md3+px) {
        max-width: 500px;
    }
    color: #ffffff !important;
    margin-bottom: 40px;
}
.firstRowLeftText {
    margin-bottom: 20px;
    @media (max-width: $md3+px) {
        max-width: 500px;
    }
}
.firstRowLeftBtn {
    margin-top: 60px;
}
.firstRowRight {
    flex: 0 1 666px;
    transform: translate(400px, 0);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0px, 0);
        opacity: 1;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 566px;
    }
    img {
        max-width: 100%;
    }
}
