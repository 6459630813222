@import "../../../styles/var";

.second {
    margin-top: 30px;
}
.secondBody {
}
.secondRow {
    display: flex;
    @media (max-width: $md3+px) {
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 600px;
        margin: 0 auto;
    }
}
.secondRowColumn {
    text-align: center;
    margin-right: 80px;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
        margin-right: 0;
        flex: 0 1 50%;
    }
    @media (max-width: 550px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:last-child {
        margin-right: 0;
    }
}
.secondRowColumnIcon {
    margin-bottom: 20px;
}
.secondRowColumnText {
}
