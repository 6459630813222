@import "../../../styles/_var.scss";
.input {
    height: 100%;

    display: block;
    input {
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 20px;
        background: transparent;
    }
    border: 1px solid #242424 !important;
    @media (max-width: $md6+px) {
        border: 1px solid #363636 !important;
    }
    border-radius: 8px;
    &.error {
        border-color: red !important;
    }
    @media (max-width: $md6+px) {
        height: 45px;
    }
}
.textarea {
    border: 1px solid #242424 !important;
    @media (max-width: $md6+px) {
        border: 1px solid #363636 !important;
    }
    &.error {
        position: relative;
    }
    @media (max-width: $md6+px) {
        height: 45px;
    }
}
.back {
    input,
    .input {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}

.textareaError {
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid red;
        border-radius: 8px;
    }
}
