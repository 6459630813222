@import "../../../styles/var";

.four {
}
.fourBody {
}
.fourRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.fourRowLeft {
    position: relative;
    left: -80px;
    transform: translate(-200px, 0);
    @media (max-width: $md3+px) {
        transform: translate(0px, 0);
    }
    @media (max-width: $md2+px) {
        left: -110px;
    }
    @media (max-width: 1340px) {
        left: -70px;
    }
    @media (max-width: $md3+px) {
        order: 1;
        margin-top: 30px;
        left: -40px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 500px;
        img {
            width: 100%;
        }
    }
}
.fourRowRight {
    flex: 0 1 554px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.fourRowRightText {
    margin-bottom: 20px;
}
.fourRowRightBtn {
    margin-top: 40px;
}
