@import "../../../styles/var";

.three {
    margin-bottom: 50px;
}
.threeBody {
}
.threeRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.threeRowLeft {
    flex: 0 1 554px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.threeRowLeftTitle {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        margin-bottom: 20px;
    }
}
.threeRowLeftText {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.threeRowRight {
    flex: 0 1 600px;
    transform: translate(200px, 0);
    @media (max-width: $md3+px) {
        transform: translate(0px, 0);
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    img {
        width: 100%;
    }
}
